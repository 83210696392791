<template>
  <div class="payment__terms-and-conditions">
    <h1 class="payment__title">{{ title }}</h1>
    <div class="payment__section">
      <div class="payment__section__element">
        <ion-icon name="information-circle-outline"/>
        <p class="payment__section__element__title">
          {{ $t('orderingProcess.mainForm.termsAndConditions.email') }}
          <br>
          <span class="payment__section__element__item">{{ email }}</span>
        </p>
      </div>
      <button v-show="false" class="payment__link">{{
          $t('orderingProcess.mainForm.termsAndConditions.emailBtn')
        }}
      </button>
    </div>
    <div class="payment__section" v-if="!orderResponse.userModel.dsgvo">
      <CheckboxFormElement
          :key="privacy.fieldLabel"
          :element="privacy"
          :passedValue="privacy.value"
          :focus="focus"
          :error="privacy.error"
          @focus="focus = $event"
          @modified="privacy.value = $event"
      />
    </div>

    <div class="payment__section" v-if="!orderResponse.userModel.termsAndConditions">
      <CheckboxFormElement
          :key="agreement.fieldLabel"
          :element="agreement"
          :passedValue="agreement.value"
          :focus="focus"
          :error="agreement.error"
          @focus="focus = $event"
          @modified="agreement.value = $event"
      />
    </div>

    <div class="payment__section" v-if="!orderResponse.userModel.newsletter">
      <CheckboxFormElement
          :key="subscribe.fieldLabel"
          :element="subscribe"
          :passedValue="subscribe.value"
          :focus="focus"
          @focus="focus = $event"
          @modified="subscribe.value = $event"
      />
    </div>
    <div class="payment__section" v-if="!orderResponse.userModel.newsletter">
      <span class="disclaimer">{{ $t('orderingProcess.mainForm.termsAndConditions.newsletterDisclaimer') }}</span>
    </div>

    <div class="payment__section" v-if="!orderResponse.userModel.termsAndConditions || !orderResponse.userModel.dsgvo">
      <span class="mandatory">*{{ $t('orderingProcess.mainForm.termsAndConditions.mandatory') }}</span>
    </div>
    <div class="payment__section">
      <Button
          :text="$t('orderingProcess.mainForm.termsAndConditions.button')"
          :toggle-text="false"
          @click="submitTransaction"
          @focus="$refs.submitTransactionBtn.$el.focus()"
          @keyup.enter="submitTransaction"
          ref="submitTransactionBtn"
          position="left"
          :disabled="isLoading"
      >
        <ButtonLoader
            v-if="isLoading"
            :size="$refs.submitTransactionBtn.$el.clientHeight"
        />
      </Button>
    </div>

  </div>
</template>

<script>
import CheckboxFormElement from "../../FormElements/CheckboxFormElement.vue";
import i18 from "../../plugins/Translations.vue"
import Error from "../../styled/Error.vue"
import Button from "../../styled/Button.vue"
import ButtonLoader from "../../styled/ButtonLoader.vue";
import {setSofortCharge, setUnzerCharge} from "../../../../functions/payment/unzerService";
import {isUndefined} from "lodash";
import axios from "axios";
import {buildPaymentApiCall} from "../../../../functions/builds";

export default {
  name: "TermsAndConditionsComponent",
  components: {CheckboxFormElement, i18, Error, Button, ButtonLoader},
  props: {
    orderResponse: {
      type: Object,
      required: true
    },
    previewMode: {
      type: String,
      require: true
    },
    title: {
      type: String,
      required: true
    },
    documentIds: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      focus: '',
      email: '',
      privacy: {
        id: "privacy",
        fieldLabel: i18.t('orderingProcess.mainForm.termsAndConditions.privacy',
            {
              link: 'https://www.gbi-genios.de/de/genios/allgemeine-geschaeftsbedingungen-agb',
              linkText: 'AGB',
              privacy: 'https://www.gbi-genios.de/de/solutionPopup/datenschutz',
              privacyText: i18.t('orderingProcess.mainForm.termsAndConditions.privacyText')
            }),
        value: this.orderResponse.userModel?.dsgvo || false,
        field: false,
        error: false,
        name: "privacy"
      },
      agreement: {
        id: "agreement",
        fieldLabel: i18.t('orderingProcess.mainForm.termsAndConditions.agreement'),
        value: this.orderResponse.userModel?.termsAndConditions || false,
        field: false,
        error: false,
        name: "agreement"
      },
      subscribe: {
        id: "subscribe",
        fieldLabel: i18.t('orderingProcess.mainForm.termsAndConditions.subscribe'),
        value: true,
        field: true,
        name: "subscribe"
      },
    }
  },
  mounted() {
    this.email = sessionStorage.getItem('defaultUserEmail')
  },
  methods: {
    validation() {
      const {privacy: {value: privacyValue}, agreement: {value: agreementValue}} = this
      this.privacy.error = false
      this.agreement.error = false
      let isValid = true

      if (!privacyValue) {
        this.privacy.error = true
        isValid = false
      }
      if (!agreementValue) {
        this.agreement.error = true
        isValid = false
      }
      return isValid
    },
    async submitTransaction() {
      this.isLoading = true
      const amount = this.orderResponse.orderDataModel.totalGross
      const isValid = this.validation()
      if (isValid) {
        const {paymentId, orderDataId, sofortId, basketId, orderPaymentId} = this.orderResponse

        let transactionId = paymentId
        if (isUndefined(transactionId))
          transactionId = JSON.parse(sessionStorage.getItem('paymentId'))?.paymentId

        sessionStorage.setItem('paymentId', JSON.stringify({paymentId: null, orderQuantity: 1, mode: null}))

        //if sofortId not undefined means that this is sofort payment type

        if (!isUndefined(sofortId)) {
          await this.submitSofort({
            amount,
            orderDataId,
            orderPaymentId,
            basketId,
            sofortId
          })
        } else {
          const data = await setUnzerCharge({
            paymentId: transactionId,
            body: {
              amount,
              orderId: orderPaymentId
            }
          })

          const params = {
            orderDataId,
            chargeId: data.id,
            shortId: data.processing.shortId,
            newsletter: this.subscribe.value,
            termsAndConditions: this.agreement.value,
            dsgvo: this.privacy.value,
            merchantMessage: data.message?.merchant
          }
          const {buy} = this.$parent.$parent.$parent
          buy({params})
        }
      }
      this.isLoading = false
    },
    async submitSofort({amount, orderDataId, orderPaymentId, basketId, sofortId}) {
      const bodyParams = {
        amount,
        currency: "EUR",
        returnUrl: window.location.href, //required
        orderId: orderPaymentId,
        paymentReference: orderDataId,
        resources: {
          typeId: sofortId,
          basketId
        }
      }

      const {data: sofortChargeData} = await setSofortCharge(bodyParams)

      //imitation of second step of payment
      const {params: paymentMethodParams} = this.orderResponse
      let params = {...paymentMethodParams, transactionId: sofortChargeData.resources.paymentId}

      const {fetchUrl, params: buildParams, config} = buildPaymentApiCall(this.documentIds, this.previewMode, this.orderResponse, params)

      const {data: fetchData} = await axios.post(fetchUrl, buildParams, config)
      // ============== end of imitation of second step ===================

      //starting the usual payment process for last step (charging)
      if (fetchData.code === "SOFORT_PAYMENT_CREATED") {
        const sofortParams = {
          orderDataId,
          chargeId: sofortChargeData.id,
          shortId: sofortChargeData.processing.shortId,
          newsletter: this.subscribe.value,
          termsAndConditions: this.agreement.value,
          dsgvo: this.privacy.value,
          merchantMessage: sofortChargeData.message?.merchant
        }

        const orderQuantity = JSON.parse(sessionStorage.getItem('paymentId'))?.orderQuantity || 0
        sessionStorage.setItem('paymentId', JSON.stringify({
          paymentId: sofortChargeData.resources.paymentId,
          orderQuantity: parseInt(orderQuantity) || 0,
          mode: this.previewMode,
          sofortParams,
          issueDate: this.orderResponse?.issueDate,
          attachmentType: this.orderResponse?.attachmentType
        }))
        window.location.assign(sofortChargeData.redirectUrl)
      }
    }
  },
  watch: {
    email(newEmail) {
      this.email = newEmail
    }
  }
}
</script>
